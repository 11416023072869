.tableCentre{
    text-align: center !important;
    height: 58px;
    vertical-align: middle !important;
    padding: 0px !important;
}
.complaint-box .rtf-table tbody td {
    text-align: center !important;
}
.complaint-box .rtf-table tbody td {
    padding: 0px !important;
    font-size: 14px;
    font-weight: bold;
}
.acceptName{
    border: 1px solid black;
    border-radius: 6px;
    height: 40px;
    width: 55%;
    margin: auto;
    display: block;
}
.complaint-box .rtf-table tbody td {
    text-align: center !important;
}
th{
    padding: 20px 10px 10px 21px;
    font-size: 16px;
}
.TableRow{
    font-size: 14px !important;
}