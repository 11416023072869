/* ThreeDotsMenu.css */

.menu-container {
    position: relative;
    display: inline-block;
}

.menu-icon {
    font-size: 24px;
    cursor: pointer;
}

.menu-items {
    position: absolute;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 150px;
    /* Set a width for the menu */
    border-radius: 4px;
    /* Rounded corners for a nicer look */
}

.menu-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Smooth transition for hover effect */
}

.menu-item:hover {
    background-color: #f0f0f0;
    /* Light gray background on hover */
}

/* Optional: Add some spacing and styling for better presentation */
/* body {
    font-family: Arial, sans-serif;
}

h1 {
    margin-bottom: 20px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */