.inline-block
{
  display: inline-block;
}
.react-resizable {
    position: relative;
    min-width: 40%;
    max-width: 60%;
  }
  .react-resizable-handle {
    position: absolute;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('../../assets/images/div.png');
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }
  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle-e {
    right: -2px;
    transform: rotate(0deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }

  .custom-box {
    overflow: visible;
  }
  .custom-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #1153aa;
    opacity: 0.75;
    border-radius: 4px;
  }
  .custom-handle-sw {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
  }
  .custom-handle-se {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
  }
  .custom-handle-nw {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
  }
  .custom-handle-ne {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
  }
  .custom-handle-w,
  .custom-handle-e {
    top: 50%;
    margin-top: -4px;
    cursor: ew-resize;
  }
  .custom-handle-w {
    left: -4px;
  }
  .custom-handle-e {
    right: -4px;
  }
  .custom-handle-n,
  .custom-handle-s {
    left: 50%;
    margin-left: -4px;
    cursor: ns-resize;
  }
  .custom-handle-n {
    top: -4px;
  }
  .custom-handle-s {
    bottom: -4px;
  }
  .lds-spinner {
    color: white;
    display: inline-block;
    width: 80px;
    height: 80px;
    bottom: 51%;
    top: 64%;
    left: 50%;
    right: 50%;
    position: absolute
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: blue;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }