.formbtn {
  margin-bottom: 1%;
}

input[type=checkbox]:checked {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input[type="checkbox"]:focus-visible {
  outline: none;
  /* Remove the focus outline */
  box-shadow: none;
  /* Remove the box shadow */
}

.table-body-align {
  /* display: flex;
justify-content: center; */
  text-align: -webkit-center;
}

.th_style {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.sticky-table-container {
  position: relative;
  overflow: auto;
  height: 480px; /* Set the desired height for the table container */
}

.sticky-table-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Set the background color according to your design */
  z-index: 1;
}