@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500;600;900&display=swap");

.file-status-container {
    margin-left: -4pc;
    padding: 10px;
}

.file-status-queue {
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    justify-content: center;
    /* gap: 1rem; */
    list-style: none;
}

.file-status-queue li {
    /* max-width: 12rem; */
    justify-self: center;
    display: grid;
    grid-template:
        "icon"
        "line"
        "dot"
        "title"
        "descr" 1fr;
    justify-items: center;
    align-items: flex-start;
    text-align: center;
}

.file-status-queue li .icon {
    grid-area: icon;
    width: 3rem;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    color: white;
    font-size: 2rem;
    border: 0.4rem solid var(--bgColor);
    border-radius: 50%;
    background: var(--accent-color);
    box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45),
        inset -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.45);
}

.file-status-queue li::before {
    content: "";
    grid-area: line;
    height: 2rem;
    border-right: 2px dotted currentColor;
}

.file-status-queue li::after {
    content: "";
    grid-area: dot;
    width: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--accent-color);
    justify-self: center;
    margin-bottom: 0.5rem;
}

.file-status-queue li .title {
    grid-area: title;
    margin-block: 0.5rem;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #545252;
}

.credits {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}

.credits a {
    color: var(--color);
}