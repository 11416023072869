.onlineStatus {
    background-color: #5CB85C !important;
    color: white;
    height: 20px;
    width: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.offlineStatus {
    background-color: #ff3232 !important;
    color: white;
    height: 20px;
    width: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.teamList {
    box-shadow: 0 0 15px #de8989d6;
    border: 1px solid #0101013b;
    border-radius: 10px;
    height: 100%;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

tr {
    border-radius: 12px;
}

tr:nth-child(even) {
    box-shadow: 0 0 15px #a9b6a9c8;
}

.sw-container {
    width: 100%;
}

.done {
    list-style: none;
    margin-top: 24px;
    border-bottom: 1px solid black;
    margin-left: -40px;
    padding: 29px;
    height: 101px;
}

.nav .nav-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 50px !important;
    line-height: 50px !important;
}

.ulNavLink {
    border: 1px solid #e3e5e8;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: block;
    /* padding: 0px; */
    box-shadow: 0 0 9px #010101;
    margin: auto;
    list-style-type: none;
    padding: 10px 10px 10px 10px;
}


.liNavLink {

    border-radius: 10px;
    /* padding: 16px 18px 13px 18px; */
    box-shadow: 0 0 4px #101010;
 
    border-bottom: 1px solid #e3e5e8;
    width: 100%;
    background-color: #fff;
    margin: 15px 0px 15px 0px;
    padding: 3px;
    cursor: pointer;
}

.titlePara {
    text-align: center;
    color: #f5f5f5;
    margin: 5px;
}

.lastel {
    list-style: none;
    margin-top: 24px;
    margin-left: -40px;
    padding: 29px;
    height: 101px;
}

.title {
    text-align: center;
}

.description {
    margin-left: 10px;
}

.nav-link {
    color: grey;
    font-weight: 500
}

.nav-link:hover {
    color: #e9e7e7;
}




.nav-link:hover {
    color: blue;
}

.nav .nav-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: auto !important;
    line-height: 50px !important;
}

.nav-pills .nav-link.active {
    color: #3B597C !important;
    background-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 600;
    border-bottom: 2px solid #3b5998 !important;
}

.tab-content {
    margin: 0.5em;
    font-size: 0.9em;
    background-color: #fff;
    height: 85%;
    color: black;
    border-radius: 15px;
}

.cardF {
    width: 100%;
    margin: auto;
    background-color: #fff;
    display: block;
    box-shadow: 0px 1.3333332538604736px 10.666666030883789px 0.3333333134651184px rgb(98 101 120 / 15%);
    border-radius: 10px;
    border: none !important;
    height: 840px;
}

.titleHeading {
    border-bottom: 1px solid #e3e5e8;
    font-size: 21px;
    font-weight: 900;
    color: #000;
    margin: 13px;
}

.thTable {
    padding: 10px;
    text-align: center;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.liNavLink .Alpha::after {
    background-color: purple;
}

.liNavLink .Bravo::after {
    background-color: purple;
}

.liNavLink .Cobra::after {
    background-color: purple;
}

.liNavLink .Delta::after {
    background-color: purple;
}

.liNavLink .Query::after {
    background-color: purple;
}

.liNavLink .Scan::after {
    background-color: purple;
}

.liNavLink .Vetting::after {
    background-color: purple;
}