.notify .notify-title {
  margin: 0;
  font-weight: bold;
  color: black;
  font-size: 1.2em;
}

.notify .notify-subtitle {
  margin: 0;
  font-weight: 500;
  color: grey;
  font-size: 14;
}

.notify .notify-card {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 1.5%;
}

.notify .notify-card-m {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 2%;
  padding: 1.5%;
}

.notify .notify-head {
  text-align: center;
  flex: 1;
  font-weight: bold;
  text-transform: uppercase;
}

.notify .notify-desc {
  margin: 0;
  color: grey;
  margin-top: 1%;
  font-size: 16px;
}
