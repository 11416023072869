.table-style {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.data-style {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 2px !important;
}

.head-style {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 2px !important;
}

.row-style:nth-child(even) {
    box-shadow: none !important;
}

.hint-title-deed {
    border-bottom: 2px solid #bbb4b4;
    padding-bottom: 2%;
}

.hint-title-deed h6 {
    color: #129b12;
    font-weight: bold;
}

.hint-title-deed p {
    font-family: unset;
    margin: 0px;
}

.hint-title-deed p span {
    color: #129b12;
    font-size: 20px;
}