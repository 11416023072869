.overview-body {
  margin: 1% 2%;
  width: 100%;
}

.overview-main {
  padding: 1%;
  background: white;
}

input[type=checkbox]:checked {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input[type="checkbox"]:focus-visible {
  outline: none;
  /* Remove the focus outline */
  box-shadow: none;
  /* Remove the box shadow */
}