#graphData1 {
  height: 100vh;  /* Make sure the height is adjustable for mobile */
  overflow-y: auto;
}

.fixed-arrow {
  position: fixed;
  width: 60px;
  height: 60px;
  font-size: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.fixed-arrow:hover {
  background-color: #0056b3; 
}

.up {
  position: fixed;
  right: 120px; 
  top: 50%;
  transform: translateY(-50%); 
}

.down {
  position: fixed;
  right: 120px; 
  top: 60%; 
  transform: translateY(-50%); 
}

.fixed-arrow.up,
.fixed-arrow.down {
  display: none;
}

/* Style for tabs */
.tabs {
  display: none;
}

.tab-button {
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  background-color: #f1f1f1;
  font-size: 16px;
  border-radius: 5px;
}

.tab-button.active {
  background-color: #4CAF50;
  color: white;
}

/* Custom styling for Tabs to display titles inline */
.custom-tabs .nav-tabs {
  display: flex;
  padding: 0;  
  margin-bottom: 0; 
}

.custom-tabs .nav-item {
  display: inline-block;
}

.custom-tabs .nav-link {
  display: inline-block;
  padding: 10px 20px; 
  text-align: center; 
  font-size: 16px; 
}

/* Make sure the full page takes white background for Audio Tab */
.full-page-container {
  min-height: 100vh;
  background-color: white; 
  padding: 0;
  margin: 0;
}

.container {
  padding: 20px;
}

.audio_div::-webkit-scrollbar {
  display: none;
}

.audio_div {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.mainAccordion {
  padding: 0px !important;
  margin-top: 2%;
}

@media screen and (max-width: 480px) {
  #graphData1 {
    /* width: 120% !important; */
    /* height: 370px !important; */
    margin-left: -40px !important;
  }

  .chartrow {
    /* padding-top: 5% !important; */
    display: flex;
    flex-direction: column;
    margin: 0px !important;
  }
  .col-sm-12 {
    padding: 10px !important; 
    width: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  #graphData1 {
    /* width: 120% !important; */
    /* height: 370px !important; */
    margin-left: -40px !important;
  }

  .chartrow {
    padding-top: 5% !important;
    margin: 0px !important;
  }
}

@media (max-width: 767px) {
  .chartrow .col-6 {
    width: 100% !important;
  }
}

/* Media query to show tabs only on mobile screens */
@media (max-width: 768px) {
  .tabs {
    display: block;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .fixed-arrow.up,
  .fixed-arrow.down {
    display: block;
  }
}
