.Flexy-table tr td {
  /* color: red;
    font-weight: bold; */
  text-align: -webkit-center;
}

.query-table-container th {
  background-color: #617ead !important;
}

.query-table-container td {
  padding: 3% 2% !important;
  font-size: 14px !important;
}

.action-btn {
  margin-top: -15px;
}