
  .dt-responsive.table-responsive {
	overflow: auto;
	max-height: 610px; /* Set a maximum height to limit the vertical scrollbar */
  }
  
  .dt-responsive.table-responsive::-webkit-scrollbar {
	width: 8px; /* Width of the scrollbar */
  }
  
  .dt-responsive.table-responsive::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Color of the scrollbar track */
  }
  
  .dt-responsive.table-responsive::-webkit-scrollbar-thumb {
	background-color: #888; /* Color of the scrollbar thumb */
  }
  
  .dt-responsive.table-responsive::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* Color of the scrollbar thumb on hover */
  }
  