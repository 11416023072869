/* Dashboard table */
#eodtable thead tr th {
  background: lightgrey;
  padding: 0.5rem;
  vertical-align: middle;
  border-color: inherit;
}

#eodtable tbody tr td {
  background: white;
  padding: 0.6rem;
  border-color: inherit;
}

.ant-calendar-picker-input.ant-input {
  line-height: 1.5;
  height: 39px;
}

.btn-primary {
  margin-top: 6px;
}

.excel-download-btn {
  border: 1px solid green !important;
  background: green !important;
  color: #fff !important;
  width: 14% !important;
  font-size: 15px !important;
  height: 37px !important;
  border-radius: 6px !important;
  float: left !important;
  margin-top: 10px !important;
}

.excel-download-btn:hover {
  border: 1px solid green !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  color: green !important;
}

.new-scroll::-webkit-scrollbar {
  width: 5px !important;
  /* background-color: red; */
}

.new-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.new-scroll::-webkit-scrollbar-thumb {
  /* background: rgba(230, 106, 106, 0.653);  */
  background: rgb(113, 113, 113);
  border-radius: 10px;
}


.team-filter-input {
  border: none;
  border-radius: 14px;
  box-shadow: 0 0 5px 2px #0c0a0b40;
  color: #000;
  display: flex;
  height: 47px;
  justify-content: space-between;
  padding: 14px;
  margin-bottom: 15px;
  width: 47%;
}

.icon-detail {
  display: flex;
  margin: auto;
}

.express-file-detatils div p span {
  font-weight: 600;
}

.express-modal-body {
  padding: 5% 5% !important;
}