.red {
	background-color: #F44336 !important;
	color:white;
	height: 20px;
	width: 30px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.green {
	background-color: #5CB85C !important;
	color:white;
	height: 20px;
	width: 30px ;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.text-c-yellow1{
	background-color: #FF9800;
	height: 20px;
	color: white;
	width: 30px ;
	font-size: 15px;
	/* position: relative;
	transform: translateX(-50%); */
}
.text-c-green1 {
	background-color: #5CB85C;
	height: 20px;
	width: 30px ;
	color: white;
	font-size: 15px;
  }
  .text-c-red1 {
	background-color: #F44336;
	height: 20px;
	width: 30px ;
	color: white;
	font-size: 15px;
  }
  .slash{
	color: #ffab0ef3 ;
  }
  .text{
    margin-bottom: 7px;
  }
  .ConT{
	text-align: center !important;
    display: inline-flex !important;
    margin-bottom: 11px;
  }