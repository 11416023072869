.content {
    margin: 2%;
}

.drop-down {
    width: 30% !important;
    float: right;
}
.drop-down1 {
    width: 50% !important;
    /* float: right; */
}
.drop-down-logo{
    margin: auto;
    width: 30%;
}
.name_select{
    width: 21%;
}
.logo-lable{
    margin: auto;
    font-weight: bold;
    font-size: 17px;
    margin-right: 5%;
}
.name_field{
    display: flex;
}

.header-title {
    font-size: 24px;
}

.add-btn {
    margin: auto;
    margin-left: 8%;
}

.document-container {
    width: 100%;
    background-color: white;
    height: auto;
    margin-top: 7%;
    padding: 0 10% 0 10%;
}
.logo{
    display: flex;
    padding-top: 2%;
}

.img-center {
    margin: auto;
    text-align: center;
    display: flex;
}

.advocates-names {
    font-weight: bold;
    display: flex;
}

.add-header {
    font-size: 16px;
}

.address {
    margin-top: 1%;
}

.address-grid{
    margin-top:-2%
}

.hr-line {
    border: 1px solid #7a7979 !important;
}
/* .form-field{
    float: right;
    margin: auto;
}
.app-id{
    display: flex;
}
.ref-no{
    display: flex;
} */
.fea_icon{
    align-items: center;
    display: flex;
    margin-left: -7px;
} 
.report-title{
    text-align: center;
    margin: 4% auto 0;
    justify-content: center;
    display: flex;
    width: 100%;
    text-decoration-line: underline;    
}
.report-name{
    text-align: center;
    margin: 4% auto 0;
    justify-content: center;
    display: flex;
    width: 100%;
}
.bold{
    font-weight: bold;
}
.los_id{
    display: flex;
}
.sub_below_para{
    display: flex;
}
.div_flex{
    display: flex;
}
.bank-branch{
    width: 20%;
    margin: auto 5%;
}
.table{
    padding: 0px !important;
}
.remarks{
    display: flex;
}
.advocate-input{
    width: 25%;
    /* float: right; */
}
.advocate-name-label{
    text-align: center;
    justify-content: center;
    display: flex;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: right;
}

.form-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-field label {
  margin-right: 10px;
}

.form-field input {
  width: 200px;
  margin-right: 10px;
}

.toggle-btn {
  border: none;
  background-color: transparent;
}

.toggle-btn i {
  color: red;
}