body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #ffffff;
  color: #fff;
}

.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  background: linear-gradient(rgb(249, 248, 247),
      rgb(251, 250, 248) 46px,
      rgb(251, 251, 249) 120px,
      rgb(248, 247, 245) 35%,
      rgb(249, 248, 246));
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: background 140ms ease-in;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.fa-add::before {
  content: "" !important;
}

.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
}

a {
  color: #d35400;
}

blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c" "\201d";
}

blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

.refresh-btn {
  float: right;
  margin-top: 5px;
  padding: 5%;
  border: 1px solid green;
  border-radius: 10px;
  background: black;
  color: white;
  width: 50px;
  height: 40px;
}

.custom-select__menu {
  z-index: 9999;
  position: relative;
}

.accordion-addon {
  overflow: visible !important;
}